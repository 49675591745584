<template>
  <div class="change-directive-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.tenderGuid"
            placeholder="所属标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearch">查询</el-button>
          <el-button @click="handleAdd">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      v-if="changeDirectiveList.length"
      :data="changeDirectiveList"
      header-cell-class-name="bg-info text-light"
      :height="tableHeight"
      border>
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column header-align="center" align="center" label="批次号" prop="directiveBatchID"></el-table-column>
      <el-table-column width="300" header-align="center" align="center" label="批准文号">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <template v-if="scope.row.fileName">
            <a target="_blank" :href="`http://47.93.222.187:5008/${scope.row.changeDirectiveGuid}.${scope.row.fileName.substr(scope.row.fileName.lastIndexOf('.') + 1)}`">
              <span>{{ scope.row.directive }}</span>
            </a>
          </template>
          <template v-else>
            <span>{{ scope.row.directive }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="变更令名称" prop="directiveName"></el-table-column>
      <el-table-column width="100" header-align="center" align="center" label="标段" prop="tenderName"></el-table-column>
      <el-table-column width="120" header-align="center" align="center" label="期数" prop="periodName"></el-table-column>
      <el-table-column width="200" header-align="center" align="center" label="变更令批复日期" prop="directiveTime"></el-table-column>
      <el-table-column width="120" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="附件" :open-delay="500" placement="top">
            <a href="#" @click="handleAttachmentClick(scope.row)" class="mr-2">
              <img style="width: 15px; height: 15px;" src="@/assets/images/svg/attachment.svg" alt="">
            </a>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="明细" :open-delay="500" placement="top">
            <a href="#" @click="handleDetailClick(scope.row)" class="mr-2">
              <img style="width: 15px; height: 15px;" src="@/assets/images/svg/detail.svg" alt="">
            </a>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog
      ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick"
      >
      <change-directive-form
        ref="changeDirectiveForm"
        :changeDirectiveModel="changeDirectiveModel">
      </change-directive-form>
    </my-dialog>

    <my-dialog
      ref="attachmentFormDialog"
      title="上传变更文件"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="doImport"
      >
      <el-upload
        class="upload-control"
        ref="upload"
        :action="uploadAction"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :limit="1"
        :accept="accept"
        :data="uploadData"
        :headers="headers"
        name="filess"
        :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
      </el-upload>
    </my-dialog>
  </div>
</template>

<script>
import changeDirectiveService from '@/services/changeDirectiveService'
import PageInfo from '@/support/PageInfo'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PageOperation from '@/mixins/PageOperation'
import ChangeDirectiveModel from '@/model/ChangeDirectiveModel'
import ChangeDirectiveForm from '@/views/ChangeDirectiveManage/ChangeDirectiveForm'
import GetTender from '@/mixins/GetTender'
import config from '@/config'
import utility from '@/common/utility'
import auth from '@/common/auth'

export default {
  name: 'ChangeDirectiveIndex',
  mixins: [
    GetTender,
    PageOperation
  ],
  components: {
    MySearchBar,
    MyDialog,
    ChangeDirectiveForm
  },
  data () {
    return {
      searchModel: {
        companyGuid: '',
        projectGuid: '',
        tenderGuid: ''
      },
      changeDirectiveList: [],
      changeDirectiveModel: new ChangeDirectiveModel(),
      formDialogTitle: '',
      uploadData: {},
      uploadAction: `${config.serviceHost}/changeDirective/UploadFile`
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.$set(this.searchModel, 'tenderGuid', '')
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.$set(this.searchModel, 'tenderGuid', '')
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  computed: {
    headers () {
      return {
        Authorization: utility.getAuthHeader(auth.getToken())
      }
    },
    companyGuid () {
      return this.searchModel.companyGuid
    },
    projectGuid () {
      return this.searchModel.projectGuid
    }
  },
  methods: {
    handleSearch () {
      this.getData()
    },
    handleAdd () {
      this.formDialogTitle = '添加变更令'
      this.changeDirectiveModel = new ChangeDirectiveModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.changeDirectiveModel = new ChangeDirectiveModel(row)
      this.formDialogTitle = '编辑变更令'
      this.$refs.formDialog.open()
    },
    handleOkClick () {
      this.changeDirectiveModel.periodName = parseInt(this.changeDirectiveModel.periodName)
      this.$refs.changeDirectiveForm.validate()(valid => {
        if (valid) {
          if (!this.changeDirectiveModel.resourceId) {
            this.save()
          } else {
            this.edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    handleDeleteClick (row) {
      this.changeDirectiveModel = new ChangeDirectiveModel(row)
      this.$confirm(`您确定要删除名为 ${this.changeDirectiveModel.directiveName} 的变更令吗？`)
        .then(() => {
          changeDirectiveService.delete(this.changeDirectiveModel.changeDirectiveGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
                this.getData()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败，请重试！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleAttachmentClick (row) {
      this.changeDirectiveModel = new ChangeDirectiveModel(row)
      this.$refs.attachmentFormDialog.open()
    },
    doImport () {
      this.uploadData = {
        changeDirectiveGuid: this.changeDirectiveModel.changeDirectiveGuid
      }

      this.uploadAction = `${config.serviceHost}/changeDirective/UploadFile?changeDirectiveGuid=${this.changeDirectiveModel.changeDirectiveGuid}`

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    handleRemove () {
    },
    handleSuccess () {
      this.getData()
      this.$message({
        type: 'success',
        message: '上传成功！'
      })
      this.$refs.attachmentFormDialog.close()
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '上传失败！'
      })
      this.$refs.attachmentFormDialog.close()
    },
    handleDetailClick (row) {
      this.changeDirectiveModel = new ChangeDirectiveModel(row)
      this.$router.push(`/changeDirectiveItem?changeDirectiveGuid=${this.changeDirectiveModel.changeDirectiveGuid}`)
    },
    getData () {
      if (this.searchModel.tenderGuid) {
        changeDirectiveService.list(new PageInfo({ pageIndex: 0, pageSize: 999 }), { tenderGuid: this.searchModel.tenderGuid })
          .then(res => {
            if (res.data.code === 1) {
              this.changeDirectiveList = res.data.data
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$message({
              type: 'error',
              message: '列表加载失败，请重试！'
            })
          })
      }
    },
    save () {
      changeDirectiveService.add(this.changeDirectiveModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this.getData()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          console.log(err.request.response)
        })
    },
    edit () {
      changeDirectiveService.edit(this.changeDirectiveModel)
        .then(res => {
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this.getData()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.change-directive-index {
  width: 100%;
  height: 100%;
}
</style>
